.navbar-logo-text.myles {
    margin-left: .5%;
}

@media only screen and (max-width: 600px) {
    .navbar-logo.myles {
        max-width: 12% !important;
    }
    .navbar-logo-text.myles {
        margin-left: .5%;
    }
}