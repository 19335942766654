.row-text-with-pic img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.pic-text {
  text-align: center;
  color: grey;
  font-size: 90%;
}
.modal-word {
  font-weight: bold;
  color: #428bca;
}
.modal-word:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .row-text-with-pic {
    flex-direction: column;
  }
  .row-pic {
    text-align: center;
    padding: 3%;
  }
}
