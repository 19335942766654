#library .hidden {
    display: none;
}

#library button {
    margin: 0 1% 1% 0;
    width: 45%;
}

#library .btnContainer {
    padding-left: 7%;
}

#library .btnContainer .full_width_btn {
    width: 91.5%;
}

#library ul {
    list-style-type: none;
  }



@media only screen and (max-width: 600px) {
    #library button {
        margin-bottom: 1%;
        width: 100%;
    }

    #library .btnContainer {
        padding-left: 0;
    }

    #library .btnContainer .full_width_btn {
        width: 100%;
    }
    
}

