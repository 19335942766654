#edglo .sidePicture {
  max-width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
#edglo .sidePictureDiv {
  flex: 1;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
  font-size: small;
  height: 100%;
}
#edglo .sidePictureDiv img {
  margin: 1% 0 1% 0;
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  #edglo .pic-and-text {
    flex-direction: column-reverse;
  }
  #edglo .pic-and-text-text {
    margin: 2% 0;
  }
  #edglo .sidePictureDiv {
    display: none;
  }
}
