@-moz-document url-prefix() {
    .emailjs1-form .sendButton {
        margin-top: -22px;
    }
}

.emailjs1-form {
    display: flex;
    padding: 0 1%
}
.contact-info-slot {
    margin-bottom: .5em;
}
.fa-phone-square-alt {
    font-size: 115%;
}
.message-text-area-emailjs1 {
    flex: 1;
}
.text-area-slot {
    margin-bottom: 22px;
}

@media only screen and (max-width: 600px) {
    .emailjs1-form {
        display: block;
        font-size: .7em;
    }
}