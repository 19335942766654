@-moz-document url-prefix() {
}

body {
  /* background-color: rgba(250, 235, 215, 0.3) !important; */
  background-color: rgba(4, 1, 130, 0.1);
}

.nav-link {
  color: white !important;
}

.nav-link:hover {
  color: #040182 !important;
  background: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.navbar-toggler {
  background-color: #1e1bc5;
  padding: 3px;
}
.navbar-toggler span {
  color: white;
}
.navMessageButton {
  background: #040182;
  border: #040182;
  padding-top: 6.4%;
}
.navMessageButton:hover {
  background: #fff;
  color: #040182;
}
/*////////////////////////////////////////*/
.highlight-on-hover {
  transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}
.highlight-on-hover:hover {
  box-shadow: 0 20px 70px 5px rgba(0, 0, 0, 0.15);
  transform: translateY(-7px);
  text-decoration: none;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
}
/* for comment pop up */
.highlight-on-hover-text {
  transition: transform 0.5s;
  position: relative;
  width: 75%;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

.highlight-on-hover-text:hover {
  box-shadow: 0 20px 50px 5px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  transform: translateY(-7px);
  text-decoration: none;
  padding: 1em;
  transition: 0.1s;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
}
.card-header,
.modal-header {
  color: white;
  background: #040182;
}

/* .blueLink {
  color: #428bca;
} */

.blueLink:hover {
  cursor: pointer;
}
.card-header {
  padding: 7px;
  text-align: center;
  font-weight: 500;
}
/*////////////////////////////////////////*/

.row-text-with-pic img {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.pic-text {
  text-align: center;
  color: grey;
  font-size: 90%;
}
.row-pic {
  text-align: center;
  padding: 1%;
}
.row-pic img {
  max-width: 100%;
}

/*////////////////////////////////////////*/

#library .card-header {
  padding: 7px;
  text-align: center;
  font-weight: 500;
  height: 40px;
  background-color: rgb(9, 37, 161) !important;
}
.card-body {
  text-align: center;
}
.modal-header {
  text-align: right;
  font-weight: 600;
}
.modal-title {
  font-size: 90%;
}
.modal-body {
  padding: 3% 6% 6% 3%;
}
.close {
  color: azure;
}
.close:hover {
  color: white;
}
s {
  color: grey;
}
.accordion .card-header,
.sendButton,
.altBlue {
  background-color: rgb(9, 37, 161) !important;
  text-align: left;
  font-weight: bold;
}
.accordion .card-body {
  text-align: left;
}
.sendButton:hover,
.altBlue:hover {
  background-color: #040182 !important;
}
/* 
.commented-text {
  border-radius: 15px;
  width: 75%;
} */

.modal-word .card-text {
  font-weight: normal;
  color: #343a40;
}

/* highlight on over effect for cards with modals */
.modal-word .card {
  transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -ms-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.modal-word .card:hover {
  box-shadow: 0 20px 70px 5px rgba(0, 0, 0, 0.15);
  transform: translateY(-7px);
  text-decoration: none;
  -webkit-transform: translateY(-7px);
  -moz-transform: translateY(-7px);
  -ms-transform: translateY(-7px);
  -o-transform: translateY(-7px);
}

@media only screen and (max-width: 1000px) {
  .row-text-with-pic {
    flex-direction: column;
  }
  .row-pic {
    text-align: center;
    padding: 1%;
  }
}

@media only screen and (max-width: 500px) {
  button.navbar-toggler {
    margin-left: 90%;
    margin-top: -13%;
  }
  .navMessageButton {
    padding-top: 1%;
    margin-left: -3%;
  }
  .modal-cards,
  .cards {
    display: block;
  }
  .modal-cards .card {
    margin-bottom: 7%;
  }
  .cards .card {
    margin-bottom: 7%;
  }
  .footer-navbar {
    display: flex !important;
  }
  .footer-img {
    max-width: 20% !important;
    margin-left: -35%;
  }
  .footer-text-right {
    display: none;
  }
  .footer-text-left {
    margin: 0 auto;
    font-size: 80% !important;
  }
}
