.accordion-with-EmailJ1.badpassword {
    max-width: 70%;
}

@media only screen and (max-width: 600px) {
    .accordion-with-EmailJ1.badpassword {
        min-width: 100%;
    }

    .badPassword.navMessageButton {
        margin-left: auto;
    }
}