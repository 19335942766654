#xy_library .hidden {
  display: none;
}

#xy_library button {
  margin: 0 1% 1% 0;
  width: 45%;
}

#xy_library .btnContainer {
  padding-left: 7%;
}

#xy_library .btnContainer .full_width_btn {
  width: 91.5%;
}

#xy_library ul {
  list-style-type: none;
}

@media only screen and (max-width: 600px) {
  #xy_library button {
    margin-bottom: 1%;
    width: 100%;
  }

  #xy_library .btnContainer {
    padding-left: 0;
  }

  #xy_library .btnContainer .full_width_btn {
    width: 100%;
  }
}
